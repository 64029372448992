import React, { useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import { useAuth} from '../../Context/AuthContext'
import axios from 'axios'

export default function InvteTeacher({setTeacherOpen, allTeachers}) {

  const [saving, setSaving] = useState(false)

  let { currentUser} = useAuth()

  //form state pg 1
  const [email, setEmail] = useState("")

 
  const handleSubmit = () => {
      let find = allTeachers.some((teacher) => teacher.email === email)
    if(!email){
        toast.error("Please add email")
    } else if(find) {
        toast.error("Teacher already exists")
    } else {
        setSaving(true)
        axios.post(`${process.env.REACT_APP_API_URL}/teachers/invite-teacher`, {email, from: currentUser.displayName})
        .then(() => {
          setEmail("")
          toast.success("Invite sent")
          setSaving(false)
        })
        .catch((e) => {
          console.log(e)
          setSaving(false)
    
        })
    }
   
  }

  return (
    <div id='side-modal'>
        <div id='side-modal-container'>
            <button onClick={() => setTeacherOpen(false)} id='close-contest-btn'>x</button>

            <div className='side-modal-header'>
                <p className='om-header' style={{fontSize: 28}}>Invite Teacher</p>

                <p className='om-subheader' style={{fontSize: 16, margin: '10px 0'}}>Add your teachers so they can get access to the Om Yoga app</p>
            </div>
              <div className='new-contest-container'>

                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Email address</p>
                <input className='modal-input' type='email'  value={email} onChange={(e) => setEmail(e.target.value)}/>
          
                <button style={{marginTop: 20}} className='primary-btn' onClick={handleSubmit} disabled={saving}>Send invite</button>
              </div>

        </div>
    </div>
  )
}
