import React, { useState, useEffect} from 'react'
import './Classes.css'
import axios from 'axios'
import { useAuth } from '../../../Context/AuthContext'
import LockedOverlay from '../LockedOverlay'
import { useOutletContext } from 'react-router-dom'
import ClassModal from './ClassModal'
import moment from 'moment'
import ClassEditModal from './ClassEditModal'

export default function Classes() {

  let { currentUser, subscription } = useAuth()

  const [tab, setTab] = useState(1)
  const [classes, setClasses] = useState([])
  const [ourTeachers, setOurTeachers] = useState([])
  const [open, setOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const { navOpen } = useOutletContext();
  const [classInfo, setClassInfo] = useState({})
  //Table state
  const [monday, setMonday] = useState([])
  const [tuesday, setTuesday] = useState([])
  const [wednesday, setWednesday] = useState([])
  const [thursday, setThursday] = useState([])
  const [friday, setFriday] = useState([])
  const [saturday, setSaturday] = useState([])
  const [sunday, setSunday] = useState([])

  useEffect(() => {
    getClasses()
    getStudioTeachers()
  }, [])

  // Helper function to find the maximum length among arrays
const findMaxLength = (...arrays) => {
  return Math.max(...arrays.map(arr => arr.length));
};

const populatedCell = (classDetails) => {
  return(
    <div>
      <p style={{fontSize: 18}}>{classDetails.name}</p>
      <p style={{fontSize: 14, color: '#fafafa'}}>{classDetails.start} - {classDetails.end}</p>
      <p style={{fontSize: 14, color: '#fafafa'}}>{classDetails.teacherName ? `(${classDetails.teacherName})` : "(Unassigned)"}</p>
    </div>
  ) 
}

  const getClasses = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/studios/get-active-classes`, {studioId: currentUser.uid})
    .then((res) => {
        // Sort the classes by day of the week and start times
        const sortedClasses = res.data.sort((a, b) => {
          const dayComparison = a.day - b.day;

          if (dayComparison !== 0) {
              return dayComparison;
          }

              return moment(a.start, 'HH:mm').diff(moment(b.start, 'HH:mm'));
        });

          setClasses(sortedClasses);

          // Separate classes by day of the week
          const daysClasses = {
              0: sortedClasses.filter((item) => item.day === 0),
              1: sortedClasses.filter((item) => item.day === 1),
              2: sortedClasses.filter((item) => item.day === 2),
              3: sortedClasses.filter((item) => item.day === 3),
              4: sortedClasses.filter((item) => item.day === 4),
              5: sortedClasses.filter((item) => item.day === 5),
              6: sortedClasses.filter((item) => item.day === 6),
          };

          // Set state for each day
          setMonday(daysClasses[1]);
          setTuesday(daysClasses[2]);
          setWednesday(daysClasses[3]);
          setThursday(daysClasses[4]);
          setFriday(daysClasses[5]);
          setSaturday(daysClasses[6]);
          setSunday(daysClasses[0]);
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const getStudioTeachers = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/studios/get-teachers-by-studio`, {studioId: currentUser.uid})
    .then((res) => {
      !res.data ? setOurTeachers([]) : setOurTeachers(res.data.teachers)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>

      {subscription === 'Free' && <LockedOverlay />}

      <div id='teacher-dash-content'>
        <button className='primary-btn' style={{position: 'absolute', top: '1rem', right: '1rem'}} onClick={() => setOpen(true)}>Add Class +</button>
        {open && <ClassModal setOpen={setOpen} teachers={ourTeachers} getClasses={getClasses}/>}
        {editOpen && <ClassEditModal setEditOpen={setEditOpen} teachers={ourTeachers} getClasses={getClasses} classInfo={classInfo}/>}

        <p className='om-header'>Classes</p>
        <p className='om-subheader'>Manage all of the studios classes and assign teachers</p>

        <div className='tab-container' style={{width: 'auto'}}>
          <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
            Active Classes
          </div>
          {/*<div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
            Inactive Classes
  </div>*/}
        </div>

        <div id='classes-container'>
          {tab ===1 &&
          <table>
            <thead>
                <tr>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednesday</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                    <th>Saturday</th>
                    <th>Sunday</th>
                </tr>
            </thead>
            <tbody>
            {[...Array(findMaxLength(monday, tuesday, wednesday, thursday, friday, saturday, sunday))].map((_, index) => (
              <tr key={index}>
                <td className={monday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => monday[index] ? (setEditOpen(true),setClassInfo(monday[index])) : setOpen(true)}>
                  {monday[index] ? populatedCell(monday[index]) : "No Class"}
                </td>
                <td className={tuesday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => tuesday[index] ? (setEditOpen(true),setClassInfo(tuesday[index])) : setOpen(true)}>
                  {tuesday[index] ? populatedCell(tuesday[index]) : "No Class"}
                </td>
                <td className={wednesday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => wednesday[index] ? (setEditOpen(true),setClassInfo(wednesday[index])) : setOpen(true)}>
                  {wednesday[index] ? populatedCell(wednesday[index]) : "No Class"}
                </td>
                <td className={thursday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => thursday[index] ? (setEditOpen(true),setClassInfo(thursday[index])) : setOpen(true)}>
                  {thursday[index] ? populatedCell(thursday[index]) : "No Class"}
                </td>
                <td className={friday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => friday[index] ? (setEditOpen(true),setClassInfo(friday[index])) : setOpen(true)}>
                  {friday[index] ? populatedCell(friday[index]) : "No Class"}
                </td>
                <td className={saturday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => saturday[index] ? (setEditOpen(true),setClassInfo(saturday[index])) : setOpen(true)}>
                  {saturday[index] ? populatedCell(saturday[index]) : "No Class"}
                </td>
                <td className={sunday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => sunday[index] ? (setEditOpen(true),setClassInfo(sunday[index])) : setOpen(true)}>
                  {sunday[index] ? populatedCell(sunday[index]) : "No Class"}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
          }

       

        </div>    
      </div>
    
    </div>
  )
}
