import { createContext, useContext, useEffect, useState } from 'react'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../Firebase';
import axios from 'axios'

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider( {children} ) {

    const [currentUser, setCurrentUser] = useState(null)
    const [role, setRole] = useState(null)
    const [subscription, setSubscription] = useState(null)
    const [isLoading, setIsLoading] = useState(true)


    const setupAxiosInterceptor = (user) => {
        axios.interceptors.response.use(
            response => response,
            async error => {
                // if the error is a 498 (unauthorized due expired token) and the error config allows retrying
                if (error.response && error.response.status === 498 && !error.config._retry) {
                    // mark the request for retry
                    error.config._retry = true;

                    try {
                        const tokenResult = await user.getIdTokenResult(true);

                        // if token refresh is successful, update the request headers with the new token
                        error.config.headers.authToken = tokenResult.token
                        axios.defaults.headers.common['AuthToken'] = tokenResult.token;

                        // retry the failed request with the updated token
                        return axios(error.config);

                    } catch (refreshError) {
                        // handle token refresh error, possibly redirect to login or display error message
                        console.error('Failed to refresh token:', refreshError);
                    }
                }

                // If the error is not a 498 or token refresh fails, return the error
                return Promise.reject(error);
            }
        );
    };

    useEffect(() => {
        let unsubscribe= onAuthStateChanged(auth, (user) => {
            if (user) {
                axios.defaults.headers.common['AuthToken'] = user.accessToken;
                setupAxiosInterceptor(user);

                auth.currentUser.getIdTokenResult(true) // and force new token generation
                .then((idTokenResult) => {
                    // Confirm the user role.
                    if (!!idTokenResult.claims) {
                        setRole(idTokenResult.claims.role)
                        setSubscription(idTokenResult.claims.subscription)
                        setIsLoading(false)
                        setCurrentUser(user)
                    } else {
                        setCurrentUser(null)
                        setIsLoading(false)
                        setRole(null)

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            } else {
                setCurrentUser(null)
                setIsLoading(false)
                setRole(false)
            }
          });

          return unsubscribe;
    }, []);

    const value = {
        currentUser, role, subscription
    }

    return (
        <AuthContext.Provider value={value}>
            {!isLoading && children}
        </AuthContext.Provider>
    )
}
