import './App.css';
import Login from './Components/Auth/Login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './Components/Studio/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RequireAuth from './Components/Auth/RequireAuth'
import StudioUI from './Components/Studio/StudioUI';
import { AuthProvider } from './Context/AuthContext';
import Timetable from './Components/Studio/Timetables/Timetable';
import Teachers from './Components/Studio/Teachers';
import Financials from './Components/Studio/Financials';
import Messages from './Components/Studio/Messages';
import Chat from './Components/Studio/Chat'
import CreateAccountContinued from './Components/Auth/CreateAccountContinued';
import TimetableEdit from './Components/Studio/Timetables/TimetableEdit';
import Settings from './Components/Studio/Settings';
import Classes from './Components/Studio/Classes/Classes';
import { UserProvider } from './Context/UserContext';
import { CommunicationProvider } from './Context/CommunicationContext';

function App() {
  return (
  <AuthProvider>
    <UserProvider >
    <CommunicationProvider>

    <BrowserRouter>
    <Routes>
      
      {/*Public routes */}
      <Route path='/login' element={<Login />} />
      <Route path='/create-account' element={<CreateAccountContinued />} />
  

      {/*Studio routes */}
      <Route element={<RequireAuth  />} >
        <Route path='/' element={<StudioUI />} >
          <Route index element={<Dashboard />}/>
          <Route path='timetable' element={<Timetable />}/>
          <Route path='timetable/:id' element={<TimetableEdit />}/>
          <Route path='classes' element={<Classes />}/>
          <Route path='teachers' element={<Teachers />}/>
          <Route path='financials' element={<Financials />}/>
          <Route path='messages' element={<Messages />} >
            <Route path=':id' element={<Chat />} />
          </Route>  
          <Route path='settings' element={<Settings />}/>

        </Route>
      </Route>

    </Routes>
    
  <ToastContainer position='top-center'/>

  </BrowserRouter>  
  </CommunicationProvider>
  </UserProvider>
  </AuthProvider>    
  );
}

export default App;
