import React, { useState, useEffect} from 'react'
import './Settings.css'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import { toast } from 'react-toastify'
import { useOutletContext, useLocation, useNavigate } from 'react-router-dom'
import Subscriptions from './Subscriptions';
// firebase
import { storage, auth } from '../../Firebase'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateProfile } from "firebase/auth";


export default function Settings() {

    let { currentUser } = useAuth()
    let navigate = useNavigate()

    const [tab, setTab] = useState(1)
    const [img, setImg] = useState("")
    const [studioName, setStudioName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [addressLineOne, setAddressLineOne] = useState("")
    const [addressLineTwo, setAddressLineTwo] = useState("")
    const [town, setTown] = useState("")
    const [county, setCounty] = useState("")
    const [postcode, setPostcode] = useState("")
    const [lat, setLat] = useState("")
    const [long, setLong] = useState("")
    const [logo, setLogo] = useState("")
    const [website, setWebsite] = useState("")

    const { navOpen } = useOutletContext();

    const location = useLocation();

    useEffect(() => {
        getStudio()
    }, [])

    useEffect(() => {
        if (location.search) {
           // set to subscriptions tab when returning from Stripe
            setTab(2)
        }
    }, [location.search]);

    const getStudio = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/studios/get-studio-by-id`, {id: currentUser.uid})
        .then((res) => {
            console.log("studio", res.data)
            setFirstName(res.data.firstName)
            setLastName(res.data.lastName)
            setStudioName(res.data.studioName)
            setAddressLineOne(res.data.addressLineOne)
            setAddressLineTwo(res.data.addressLineTwo)
            setTown(res.data.town)
            setCounty(res.data.county)
            setPostcode(res.data.postcode)
            setLat(res.data.lat)
            setLong(res.data.long)
            setLogo(res.data.logo)
            setWebsite(res.data.website)
        })
        .catch((error) => {
            console.log(error)
        })
    }


    const updateInfo = () => {

        let payload = {
            studioName,
            firstName,
            lastName,
            website,
            addressLineOne,
            addressLineTwo,
            town,
            county,
            website
        }

        if (!firstName || !lastName || !studioName) {
            toast.error("Please add names")
        } else if(website && (!website.startsWith('http://') && !website.startsWith('https://') )) {
            toast.error("Website must start with http")
        } else {
            updateProfile(auth.currentUser, {
                displayName: studioName
            }).then(() => {
                axios.post(`${process.env.REACT_APP_API_URL}/studios/update-info`, {_id: currentUser.uid, payload})
                .then((res) => {
                    getStudio()
                    toast.success("Updated successfully")
                })
                .catch((e) => {
                    console.log(e)
                })
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    // firebase storage

    const imgRef = ref(storage, `studios/logos/${currentUser.uid}`);
    const updateImg = (file) => {
        if (file?.size > 2097152) {
            toast.error("Image must be less than 2mb")
        } else if (!file) {
            return
        } else {
            uploadBytes(imgRef, file).then(() => {
                getDownloadURL(imgRef)
                .then((url) => {
                    updateProfile(auth.currentUser, {
                        photoURL: url
                    }).then(() => {
                        setLogo(url)
                        saveLogo(url)
                    }).catch((error) => {
                        console.log(error)
                        toast.error("Error")
                    });
                })
                .catch((error) => {
                    console.log(error)
                });
            });
        }
    }

    const saveLogo = (url) => {
        axios.post(`${process.env.REACT_APP_API_URL}/studios/update-logo`, {_id: currentUser.uid, logo: url})
        .then(() => {
            console.log("Updated")
            navigate(0)
        })
        .catch((error) => {
            console.log(error)
        })
    }


    return (
        <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>
            <div id='business-dash-content'>
                <p className='om-header'>Settings</p>
                <p className='om-subheader'>View and manage your personal and professional details</p>

                <div style={{width: '100%', minHeight: 300, display: 'flex', flexWrap: 'wrap'}}>
                    <div className='tab-container' style={{width: 300}}>
                        <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
                            <p>Studio</p>
                        </div>

                        <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
                            <p>Subscriptions</p>
                        </div>
                    </div>

                    {tab === 1 &&
                        <>
                            <div className='profile-container'>
                                <label>Profile Picture</label>
                                <div id='logo-preview' style={logo ? {backgroundImage: `url(${logo})`, backgroundSize: 'cover', backgroundPosition: 'center'} : null}>
                                    <input type="file" accept="image/*" id='file-input'  onChange={(e)=>updateImg(e.target.files[0])}/>
                                </div>
                            </div>

                            <div className='profile-container'>
                                <label>Studio name</label>
                                <input className='om-input' style={{width: 500, maxWidth: '60vw'}} value={studioName} onChange={(e) => setStudioName(e.target.value)}/>
                            </div>

                            <div className='profile-container'>
                                <label>Website</label>
                                <input className='om-input' placeholder='Inc. http://' style={{width: 500, maxWidth: '60vw'}} value={website} onChange={(e) => setWebsite(e.target.value)}/>
                            </div>

                            <div className='profile-container'>
                                <label>First name</label>
                                <input className='om-input' style={{width: 500, maxWidth: '60vw'}} value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                            </div>

                            <div className='profile-container'>
                                <label>Last name</label>
                                <input className='om-input' style={{width: 500, maxWidth: '60vw'}} value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                            </div>

                            <div className='profile-container'>
                                <label>Address</label>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <input className='om-input' placeholder='Line 1' style={{width: 500, maxWidth: '60vw'}} value={addressLineOne} onChange={(e) => setAddressLineOne(e.target.value)}/>
                                    <input className='om-input' placeholder='Line 2' style={{width: 500, maxWidth: '60vw'}} value={addressLineTwo} onChange={(e) => setAddressLineTwo(e.target.value)}/>
                                    <input className='om-input' placeholder='Town' style={{width: 500, maxWidth: '60vw'}} value={town} onChange={(e) => setTown(e.target.value)}/>
                                    <input className='om-input' placeholder='County' style={{width: 500, maxWidth: '60vw'}} value={county} onChange={(e) => setCounty(e.target.value)}/>
                                    <input className='om-input' placeholder='Postcode' style={{width: 500, maxWidth: '60vw'}} disabled value={postcode} onChange={(e) => setPostcode(e.target.value)}/>
                                </div>
                            </div>

                            <button className='primary-btn' onClick={updateInfo}>Update</button>
                        </>
                    }

                    {tab === 2 &&
                        <Subscriptions />
                    }
                </div>
            </div>
        </div>
    )
}
