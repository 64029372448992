import { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useAuth } from './AuthContext';

const CommunicationContext = createContext();

export function useCommunication() {
    return useContext(CommunicationContext)
}

export function CommunicationProvider( {children} ) {

    const [messages, setMessages] = useState([])
    const [unreadMessages, setUnreadMessages] = useState(0)

    const [isLoading, setIsLoading] = useState(false)

    const { currentUser } = useAuth()
   
    useEffect(() => { 
        if(currentUser){
             getMessages()
        }
    }, [currentUser]);

    const getMessages = () => {
            axios.post(`${process.env.REACT_APP_API_URL}/chats/get-studio-chats-by-id`, {id: currentUser.uid})
            .then((res) => {
                const sortedMessages = res.data.sort((a, b) => b.lastMsgAdded - a.lastMsgAdded);
                setMessages(sortedMessages)
                setIsLoading(false)
                let unread = res.data.filter((msg) => msg.studioRead === false)
                setUnreadMessages(unread.length)
            })
            .catch((e) => {
                console.log(e)
                setIsLoading(false)
            })
    }

    const value = {
        messages, getMessages, unreadMessages
    }

    return (
        <CommunicationContext.Provider value={value}>
            {!isLoading && children}
        </CommunicationContext.Provider>
    )
}
