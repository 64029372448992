import React, { useState, useEffect} from 'react'
import './Teachers.css'
import axios from 'axios'
import InviteTeacher from './TeacherInvite'
import { useAuth } from '../../Context/AuthContext'
import { useUser } from '../../Context/UserContext'
import LockedOverlay from './LockedOverlay'
import { useNavigate, useOutletContext } from 'react-router-dom'

export default function Teachers() {

  let { currentUser, subscription } = useAuth()
  let { userInfo, getUserInfo } = useUser()

  let navigate = useNavigate()

  const [tab, setTab] = useState(1)
  const [allTeachers, setAllTeachers] = useState([])
  const [ourTeachers, setOurTeachers] = useState([])
  const [teacherOpen, setTeacherOpen] = useState(false)
  const [sending, setSending] = useState(false)

  const { navOpen } = useOutletContext();

  useEffect(() => {
    getTeachers()
  }, [])

  useEffect(() => {
    let studioTeachers = userInfo?.teachers || [];
    if (studioTeachers.length > 0) {
        let updated = studioTeachers.map((teacher) => {
            let info = allTeachers.find((teach) => teach._id === teacher.teacherId);
            if (info) {
                // Merge userInfo.teacher object with allTeachers object
                return { ...teacher, ...info };
            } else {
                return null; // Handle the case where teacherId is not found in allTeachers
            }
        }).filter(Boolean); // Remove any null entries
        setOurTeachers(updated);
    } else {
        setOurTeachers([]);
    }
}, [userInfo, allTeachers]);

  const getTeachers = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/teachers/get-teachers`)
    .then((res) => {
      setAllTeachers(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }



  const addTeacher = (teacher) => {

    let payload = {
      teacherId: teacher._id,
      img: teacher.img,
      name: `${teacher.firstName} ${teacher.lastName}`,
      status: 'Pending'
    }

    axios.post(`${process.env.REACT_APP_API_URL}/studios/add-teacher`, {studioId: currentUser.uid, payload})
    .then((res) => {
      getUserInfo()
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const messageTeacher = (teacher) => {
    setSending(true)
    console.log(teacher)
    let payload = {
      studioId: currentUser.uid,
      studioName: currentUser.displayName,
      studioEmail: currentUser.email,
      studioRead: true,
      studioImg: userInfo?.logo,
      senderName: `${teacher.firstName} ${teacher.lastName}`,
      senderEmail: teacher.email,
      senderId: teacher._id,
      senderImg: teacher.img,
      senderRead: false,
      senderType: "Teacher",
      lastMsgAdded: new Date(),
    }

    axios.post(`${process.env.REACT_APP_API_URL}/chats/open-chat`, payload)
    .then((res) => {
      navigate(`/messages/${res.data._id}`)
      setSending(false)
    })
    .catch((e) => {
      console.log(e)
      setSending(false)

    })
  }

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>
      {subscription === 'Free' && <LockedOverlay />}
      <div id='teacher-dash-content'>
        <button className='primary-btn' style={{position: 'absolute', top: '1rem', right: '1rem'}} onClick={() => setTeacherOpen(true)}>Invite Teacher +</button>
        {teacherOpen && <InviteTeacher allTeachers={allTeachers} setTeacherOpen={setTeacherOpen}/>}

        <p className='om-header'>Teachers</p>
        <p className='om-subheader'>Manage and find the perfect teachers for your studio</p>

        <div className='tab-container' style={{width: 400}}>
          <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
            Our Teachers
          </div>
          <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
            Find Teachers
          </div>
        </div>

        <div id='teacher-list-container'>

          {tab ===1 &&
          <>
          {ourTeachers?.length < 1 
          ?
          <p>No teachers added</p>
          : 
          ourTeachers?.map((teacher, i) => {
            return (
              <div key={i} className='teacher-snapshot'>
                  <img style={{height: 60, width: 60, borderRadius: 8, backgroudColor: '#f1f1f1', backgroundSize: 'cover', backgroundPosition: 'center', marginRight: 10}}  src={teacher.img? teacher.img : require('../../Assets/Icons/user-icon.jpeg')} alt={teacher.firstName}/>
                  <div style={{padding: 10}}>
                    <p>{teacher.name}</p>
                    <p>{teacher.status}</p>
                  </div>
                  <button className='sml-btn' disabled={sending} onClick={() => messageTeacher(teacher)}>Message</button>

                </div>
            )
          })}
          </>
          }

        {tab === 2 &&
          <>
            {allTeachers.length < 1 
            ?
            <p>No teachers added</p>
            : 
            allTeachers.map((teacher, i) => {
              return (
                <div key={i} className='teacher-snapshot'>
                  <img style={{height: 60, width: 60, borderRadius: 8, backgroudColor: '#f1f1f1', objectFit: 'cover', objectPosition: 'center', marginRight: 10}}  src={teacher.img? teacher.img : require('../../Assets/Icons/user-icon.jpeg')} alt={teacher.firstName}/>
                  <div style={{padding: 10}}>
                    <p>{teacher.firstName} {teacher.lastName}</p>
                    {ourTeachers?.some((obj) => obj.teacherId === teacher._id) ?
                      <p style={{color:'green'}}>Added</p>
                      :
                      <button className='primary-btn' style={{height: 28, fontSize: 14, margin: '5px 0', width: 130}} onClick={() => addTeacher(teacher)}>Add to studio</button>

                    }

                  </div>
                  <button className='sml-btn' disabled={sending} onClick={() => messageTeacher(teacher)}>Message</button>

                </div>
              )
            })}
            </>
          }

        </div>    
      </div>
    
    </div>
  )
}
