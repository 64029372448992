import React, { useEffect, useState, useRef} from 'react'
import { useOutletContext, useParams } from 'react-router-dom';
import { useAuth } from '../../../Context/AuthContext';
import axios from 'axios'
import moment from 'moment-timezone'
import './ScheduleView.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import mat from '../../../Assets/Icons/mat.png';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import upload from '../../../Assets/Icons/upload.png'
import edit from '../../../Assets/Icons/edit.png'
import editCircle from '../../../Assets/Icons/edit-circle.png'
import save from '../../../Assets/Icons/save.png'
import bin from '../../../Assets/Icons/bin.png'
import AddClassModal from './AddClassModal';
import EditClassModal from './EditClassModal';

export default function TimetableEdit() {
    
    const { navOpen } = useOutletContext();
    const {currentUser} = useAuth()
    let {id} = useParams();
    let navigate = useNavigate();
    const [timetable, setTimetable] = useState("")

    const [open, setOpen] = useState(false);
    const [openClass, setOpenClass] = useState(false);
    const [openNewClass, setOpenNewClass] = useState(false);
    const [openEditClass, setOpenEditClass] = useState(false);

    const [otherClasses, setOtherClasses] = useState([])
    const [teacherList, setTeacherList] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [tempTeachers, setTempTeachers] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTeacher, setSelectedTeacher] = useState("");
    const [newClassTeacher, setNewClassTeacher] = useState("");     //Used to pass teacher id when adding a new class
    const [classInfo, setClassInfo] = useState("");
    const [loading, setLoading] = useState(false)
    const [classes, setClasses] = useState([])
    const [importedClasses, setImportedClasses] = useState([])
    const [unassigned, setUnassigned] = useState([])
    const [studioInfo, setStudioInfo] = useState("")
    const [title, setTitle] = useState("")

    const [editTitle, setEditTitle] = useState(false)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseClass = () => setOpenClass(false);
    const closeAddModal = () => {
        setOpenNewClass(false);
        setSelectedDate("")
        setSelectedTeacher("")
    }

 
    //Intitial load
    useEffect(()=>{
        getTimetable()
        getTeachers()
        getClasses()
        getStudio() 
    }, [])

    useEffect(()=>{
        if(timetable){
            setClasses(timetable.classes)
        }
    }, [timetable])

    const getTimetable = () =>{
        axios.post(`${process.env.REACT_APP_API_URL}/studios/get-timetable-by-id`,{id})
        .then(function(res){
            if(res.data.published){
                setTimetable(res.data)

            } else {
                setTitle(res.data.title)
                setTimetable(res.data)
                setTeachers(res.data.teachers)
                setTempTeachers(res.data.teachers)
                setClasses(res.data.classes)
                //Get list of classes with no teacher assigned
                 let filtered = res.data.classes.filter((item) => !item.teacherId)
                 setUnassigned(filtered)
            }
         
        })
        .catch((e)=>{
          console.log(e)
        })
    }

    const getStudio = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/studios/get-studio-by-id`, {id: currentUser.uid})
        .then((res) => {
          setStudioInfo(res.data)
        })
        .catch((e) => {
          console.log(e)
        })
      }

    const getClasses = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/studios/get-active-classes`, {studioId: currentUser.uid})
        .then((res) => {
            setImportedClasses(res.data)
         
        })
        .catch((e) => {
          console.log(e)
        })
      }

    const getTeachers = () =>{
        axios.post(`${process.env.REACT_APP_API_URL}/studios/get-teachers-by-studio`, {studioId: currentUser.uid})
        .then((res)=>{
          setTeacherList(res.data.teachers)
        })
        .catch((e)=>{
          console.log(e)
        })
      }
  
    const addTeachers = (teacher)=>{
     
        if (tempTeachers.some(member => member.id === teacher.teacherId)) {
            return
          } else {
            setTempTeachers(prev=> [...prev, {id: teacher.teacherId, name: `${teacher.name}`, img: teacher.img}])
          }
      }

      const addClassToTeacher = (teacherId, classId) => {
        let teacherInfo = teachers.find((teacher) => teacher.id === teacherId);
        
        // Find the index of the object with classid
        const indexToUpdate = classes.findIndex(obj => obj._id === classId);
        
        // Check if the object with id exists
        if (indexToUpdate !== -1) {
            // Create a new array with the updated values
            const updatedClasses = classes.map((item, index) => {
                if (index === indexToUpdate) {
                    return {
                        ...item,
                        teacherId: teacherInfo.id,
                        teacherName: teacherInfo.name
                    };
                }
                return item;
            });
            
            axios.post(`${process.env.REACT_APP_API_URL}/studios/update-timetable-classes`, { timetableId: id, classes: updatedClasses })
                .then(() => {
                    getTimetable();
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            console.log("Object with id not found");
        }
    };
    
    const removeTeacher = (teacher) => {
        if (window.confirm("Are you sure you want to remove this teacher?")) {
    
            let teacherId = teacher.id;
    
            let teachersClasses = classes.filter((item) => item.teacherId === teacherId);
    
            if (teachersClasses.length > 0) {
                // Remove teacher from classes
                const updatedClasses = classes.map((item) => {
                    if (item.teacherId === teacherId) {
                        return {
                            ...item,
                            teacherId: "",
                            teacherName: "",
                            teacherImg: ""
                        };
                    }
                    return item;
                });

    
                axios.post(`${process.env.REACT_APP_API_URL}/studios/update-timetable-classes`, { timetableId: id, classes: updatedClasses })
                    .then(() => {
                        getTimetable();
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            } else {
                console.log("No classes assigned to this teacher.");
            }

            //Remove teacher from timetable
            let newTeachers = teachers.filter((teacher) => teacher.id !== teacherId)

            axios.post(`${process.env.REACT_APP_API_URL}/studios/save-teachers`,{id, teachers: newTeachers})
            .then(function(){
                getTimetable()
            })
            .catch((e)=>{
                console.log(e)
            })
    
        } else {
            return;
        }
    }

      const saveTeachers = () =>{
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/studios/save-teachers`,{id, teachers:tempTeachers})
                .then(function(res){
                    getTimetable()
                    handleClose()
                    setLoading(false)
                })
                .catch((e)=>{
                console.log(e)
                setLoading(false)

                })
      }

      const handleCellClick = (date, teacher) => {
        let filtered = classes.filter((item, i) => { 
            if(item.teacherId == teacher.id && (moment(item.day).format('YYYY-MM-DD')) === moment(date).format('YYYY-MM-DD')){
                item.index=i
                return item
            }
        })

           if(filtered.length > 0){
            setOtherClasses(filtered)
            setOpenClass(true)
            setSelectedDate(date)
            setSelectedTeacher(teacher.name)
            setNewClassTeacher(teacher.id)
          } else {
            setOtherClasses([])
            setOpenClass(true)
            setSelectedDate(date)
            setSelectedTeacher(teacher.name)
            setNewClassTeacher(teacher.id)
          }
      }

      const addClass = () => {
            setOpenNewClass(true)
            setOpenClass(false)
    }

    const removeClass = (selectedClass) => {
        if(window.confirm("Are you sure you want to delete this class?")){
            let filtered = classes.filter((item)=> selectedClass._id !== item._id)
             axios.post(`${process.env.REACT_APP_API_URL}/studios/remove-class-from-timetable`, {id, classes: filtered})
             .then( function(res){
                 getTimetable()
                 handleCloseClass()
                 })
             .catch((e)=>{console.log(e);setLoading(false)})
             
        } else {
            return
        }
  
}
    const updateShift = (selectedShift, start, end) => {
        let new_array = classes.map((walk) => walk._id == selectedShift._id ? {...walk, start : start, end: end} : walk);

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/change-times`, {id, walks: new_array, walkId: selectedShift._id, start, end}, {params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
        .then( function(res){
            setLoading(false)
            handleCloseClass()
            getTimetable()
            toast.success("Successfully updated")
            })
        .catch((e)=>{console.log(e);setLoading(false)})
     
}

  
    const returnShift = (teacher, date) =>{
        let filtered = classes.filter((item)=> item.teacherId == teacher.id && moment(item.day).tz('Europe/London').startOf('day').isSame(moment(date).tz('Europe/London').startOf('day')))
        //Render single class
        if(filtered.length === 1){
           
                return <div style={{display:'flex', flexDirection:'column', height:'100%', justifyContent:'center', alignItems: 'center'}} >
                            <p>{filtered[0].name}</p>
                            <p style={{color: 'grey'}}>{filtered[0].start} - {filtered[0].end}</p>
                        </div>

           
        //Check for multiple classes
        } else if(filtered.length > 1){
         
                return <div style={{display:'flex',flexDirection:'column', height:'100%', justifyContent:'center', alignItems: 'center'}} >
                            <img style={{height: 30, width: 30, objectFit:'contain'}} src={mat} alt='Yoga mat icon'/>
                            <p>{filtered.length} Classes</p>
                        </div>

        } else {
            return <div></div>
        }
    }

    const publish = () =>{

        if(window.confirm("Are you sure you want to publish timetable?")){
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_URL}/studios/publish-timetable`, 
            {
                id, 
                classes, 
                lat: studioInfo.lat, 
                long: studioInfo.long, 
                studioId: currentUser.uid, 
                timetableId: id, 
                venueAddress: `${studioInfo.addressLineOne} ${studioInfo.addressLineTwo} ${studioInfo.town} ${studioInfo.county} ${studioInfo.postcode}`
            })
            .then( function(res){
                getTimetable()
                toast.success(res.data)
                setLoading(false)
                })
            .catch((e)=>{
                console.log(e);
                toast.error("Error publishing")
                setLoading(false)
            })
        } else {
            return
        }
        
    }

    const deleteRota = () =>{

        if(window.confirm("Are you sure you want to delete rota?")){
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_URL}/studios/delete-unpublished-timetable`, {_id: id})
            .then( function(res){
                navigate('/timetable')
                toast.success(res.data)
                setLoading(false)
            })
            .catch((e)=>{console.log(e);setLoading(false)})
        } else {
            return
        }
        
    }

    const importClasses = () => {
        if(importedClasses.length < 1){
            toast.error("No classes added")
        } else {
            if(window.confirm("Importing will overwrite the existing timetable. Continue?")){
                //Add saved classes to timetable
                let dates = timetable.dates.map((day) => {
                    return  { number: moment(day).day(),  day }
                })

                //Create list of teachers
                
                // Reducer function to extract unique user objects without empty teacherId
                const userReducer = (accumulator, currentObject) => {
                    // Check if teacher with the same id already exists in the accumulator
                    const existingTeacher = accumulator.find(teacher => teacher.id === currentObject.teacherId);
                
                    // Check if teacherId is not an empty string
                    const isValidTeacherId = currentObject.teacherId.trim() !== '';
                
                    // If the teacher doesn't exist and teacherId is valid, add the teacher object to the accumulator
                    if (!existingTeacher && isValidTeacherId) {
                    accumulator.push({
                        id: currentObject.teacherId,
                        name: currentObject.teacherName,
                        img: currentObject.teacherImg
                    });
                    }
                
                    // Return the updated accumulator for the next iteration
                    return accumulator;
                };
                
                // Use Array.reduce() to create a new array of unique user objects without empty teacherId
                const newArrayofTeachers = importedClasses.reduce(userReducer, []);

                //Create array of classes
                // Function to replace day number with actual date string
                const replaceDayWithDateString = (object) => {
                    const dateObject = dates.find((date) => date.number === object.day);
                    if (dateObject) {
                        object.day = moment(dateObject.day).format('YYYY-MM-DD');
                    }
                    return object;
                };
                
                // Create a new array with replaced date strings
                const newClassesArray = importedClasses.map(replaceDayWithDateString);
                
                axios.post(`${process.env.REACT_APP_API_URL}/studios/import-from-template`, {timetableId: id, classes: newClassesArray, teachers: newArrayofTeachers})
                .then(() => {
                    getTimetable()
                })
                .catch((e) => {
                    console.log(e)
                })
            } else {
                return
            }
         
            }
    }

    const saveTitle = () => {
        if(!title){
            toast.error("Please add a title")
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/studios/update-timetable-title`, {timetableId: id, title})
            .then(()=>{
                getTimetable()
                setEditTitle(false)
            })
            .catch((e)=>{
              console.log(e)
            })
        }
       
    }

    const editClass = (info) => {
        setClassInfo(info)
        setOpenEditClass(true)
        setOpenClass(false)

    }

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'} style={{padding: '2rem 1rem'}}>
        {timetable &&
        <>
        <div className='om-row' style={{alignItems: 'center'}}>
            {editTitle ? 
            <>
                <input id='edit-title' type='text' value={title} onChange={(e) => setTitle(e.target.value)}/>
                <img src={save} style={{width:20, height:20}} onClick={saveTitle}/>
            </>
            :
            <>
                <h1 className='om-header' style={{marginRight: 20}}>{title}</h1>
                <img src={edit} style={{width:20, height:20}} onClick={() => setEditTitle(!editTitle)}/>
            </>
           

            }
        </div>
        <p className='om-subheader'>Manage your weekly timetable of classes and teachers</p>

        <div className='om-row'>
            <button  className='primary-rota-btn' disabled={loading} onClick={importClasses}>
                <img style={{height: 20, width: 20, objectFit:'contain', marginRight: 10}} src={upload}/>
                Import Classes
            </button>
            <button className='secondary-rota-btn' disabled={loading} onClick={addClass}>Add Class +</button>
        </div>

        <table id='rota-table'>
            <thead>
                <tr>
                    <th className='om-subheader' style={{textAlign:'center', fontSize: 16, opacity: 1}}>Teachers</th>
                    {timetable.dates.map((date,i)=>{
                        return <th key={i} style={{textAlign:'center', fontSize: 16, opacity: 1}} className='om-subheader'>{moment(date).format('ddd, Do MMM')}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {teachers.map((teach, i)=>{
                    return ( 
                <tr key={i}>
                    <td>
                        <div className='rota-staff-table-container'>
                            <img className='rota-staff-img' src={teach.img? teach.img : require('../../../Assets/Icons/user-icon.jpeg')}/>
                            <h3 className='om-header' style={{fontSize: 18}}>{teach.name}</h3>
                            <PersonRemoveIcon color='error' onClick={()=>removeTeacher(teach)}/>
                        </div>
                    </td>
                        {timetable.dates.map((date,i)=>{
                            
                        return( 
                            <td  key={i}>
                                <div className='date-cell hover' onClick={()=>handleCellClick(date, teach)}>
                                    {returnShift(teach, date)}
                                </div>
                            </td>
                        )
                    })}

                </tr>
                )})}
               
                 <tr>
                    <td  onClick={handleOpen}><div id='add-employee-btn'><EmojiPeopleIcon sx={{color: '#a28f89', marginRight: 3}}/> <p>+/- Teachers</p></div></td>
                </tr>
                
            </tbody>
        </table>
        {!timetable.published && <>
            <button  className='primary-rota-btn' disabled={loading} onClick={publish}>Publish</button>
            <button className='secondary-rota-btn' disabled={loading} onClick={deleteRota}>Delete</button>
        </>
        }
        {
            //List unassigned classes
            unassigned.length > 0 &&
            <table>
                <tbody>
                    {unassigned.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td style={{padding: 10}}>{item.name}</td>
                                <td style={{padding: 10}}>{moment(item.day).format('ddd, Do MMM')}</td>
                                <td style={{padding: 10}}>{item.start} - {item.end}</td>
                                <td style={{padding: 10}}>
                                    <select className='unassigned-select' value={item.teacherId} onChange={(e) => addClassToTeacher(e.target.value, item._id)}>
                                        <option value="">Unassigned</option>
                                        {teachers.map((teacher, i) => {
                                            return (
                                                <option key={i} value={teacher.id}>{teacher.name}</option>
                                            )
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <img className='hover' style={{width: 25, height: 25}} src={bin} alt='Om Yoga booking app delete class button' onClick={()=>removeClass(item)}/>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        }
        </>
}
    {openNewClass && 
        <AddClassModal 
            closeAddModal={closeAddModal} 
            teachers={teachers} 
            dates={timetable.dates}
            timetableId={id}
            classes={classes}
            getTimetable={getTimetable}
            selectedTeacher={newClassTeacher}
            selectedDate={selectedDate}
        />
    }

        {openEditClass && 
        <EditClassModal 
            setOpenEditClass={setOpenEditClass} 
            teachers={teachers} 
            dates={timetable.dates}
            timetableId={id}
            classes={classes}
            getTimetable={getTimetable}
            classInfo={classInfo}
        />
    }

    {/*Teacher modal*/}
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <h2>Select teachers</h2>
          {teacherList?.length > 0 
          && 
            teacherList.map((employee, i)=>{
              return(
                   <div key={i} 
                    className={teachers.some(staff => staff.id === employee.teacherId) || tempTeachers.some(staff => staff.id === employee.teacherId)? "staff-name-selected": 'staff-name-container'}
                    onClick={()=>addTeachers(employee)}>
                       <p>{employee.name}</p>
                   </div>)
          })}
            <button disabled={loading} onClick={saveTeachers}>Save</button>
            <button onClick={()=>{handleClose();setTempTeachers(teachers)}}>Cancel</button>
        </Box>
      </Modal>

    {/*Class modal*/}
      <Modal open={openClass} onClose={handleCloseClass} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
        {selectedDate && selectedTeacher && otherClasses.length > 0 && 
        <>

                <h2>{moment(selectedDate).format('ddd, Do MMM')} - {selectedTeacher}</h2>
              
                {otherClasses.map((shift, i)=>{

                    return  <div key={i} className='om-row' style={{justifyContent: 'space-between', margin: '5px 0'}}>
                                <p>{shift.name}</p>
                                <p>{shift.start} - { shift.end}</p>
                                <div>
                                    <img className='hover' style={{width: 25, height: 25, margin: 5}} src={editCircle} alt='Om Yoga booking app delete class button' onClick={()=>editClass(shift)}/>
                                    <img className='hover' style={{width: 25, height: 25, margin: 5}} src={bin} alt='Om Yoga booking app delete class button' onClick={()=>removeClass(shift)}/>
                                </div>
                            </div> 
                })}
               
                <button className='primary-btn' onClick={() => addClass()}>
                    Add another class +
                </button>
            </>
            }

            {
                selectedDate && selectedTeacher && otherClasses.length < 1 
                && 
                <>
                    <h2>{moment(selectedDate).format('ddd, Do MMM')} - {selectedTeacher}</h2>

                    <p>No class added</p>
                    <button className='primary-btn' onClick={() => addClass()}>
                        Add class +
                    </button>
                </>
            }
        </Box>
      </Modal>
    </div>
  )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow:'scroll'
  };