import React, { useState, useEffect } from 'react'
import { useAuth } from '../../Context/AuthContext'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

//import './Checkout.css'
import axios from 'axios'
import { toast } from 'react-toastify'


// Overview of studio subscriptions:
//  - signup page for studios to upgrade to premium
//  - only monthly subscription available for now
//  - cancellation is by stopping auto renew, subscription remains to end of period (month)
//  - default free, allows listing
//  - premium allows calendar, scheduling and teacher inclusion (plus later student subcription)
//  - flow
//      -> sign up page, select premium
//      -> bacs payment with stripe handled checkout page
//      -> session and subscription info with customer id arrives in the webhook
//      -> set firebase to premium for access change (and internal db attributes)
//      -> returning to premium page shows end date and cancellation
//  - signup competoin occurs through webhooks asynchronously
//    therefore there are three states handled here
//      - studio has no active subscription and firebase shows as Free
//      - Firebase shows as Premium
//      - studio has an active subscription and firebase shows as Free -> subscription pending
//  - since no polling of firebase to update to Premium, this is checked when we leave this page
//    to another studio page. If it's not available any page refresh or login will also check
//    by virtue of auth retrieving new token each time
//  - note that when their subscription ends it will also require polling to capture they are now
//    on free subscription since if they have remained logged in they will still have the old firebase
//    details. But this is not considered an issue since it would not be expected to persist like that


const Subscriptions = () => {
    let { currentUser, subscription } = useAuth()
    const [studio, setStudio] = useState("")
    const [stripeSubscription, setStripeSubscription] = useState("")
    const [loading, setLoading] = useState(true)
    const [processingCheckout, setProcessingCheckout] = useState(false)
    const [waitingforUpgrade, setWaitingForUpgrade] = useState(false)

    const location = useLocation();

    const subsriptionsAllowed = process.env.REACT_APP_STUDIO_SUBSCRIPTION_ALLOWED === "true"

    // only monthly payments available for now
    // TODO: magic number! this will resolve once we create offers and products through admin panel
    const monthlyPrice = 9900

    useEffect(() => {
        getStudio()
    }, [])

    useEffect(() => {
        if (location.search.includes('success')) {
            // set to subscriptions tab when returning from Stripe
            setWaitingForUpgrade(true)
        }
    }, [location.search]); 

    useEffect(() => {
        // direct check whether we have an active subscription
        // therefore don't need to rely on webhook updates after payment
        // only call once studio data is available and only need to do once
        if (studio && loading) {
            getActiveStripeSubscription()
        }
    }, [studio])

    const getStudio = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/studios/get-studio-by-id`, {id: currentUser.uid})
        .then((res) => {
            setStudio(res.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const getActiveStripeSubscription = async () => {
        // search for active subscriptions for a customer by their stripe id
        // this is a way to find any that have been created but not yet registered by the webhook
        if (studio.subscriptionId && (subscription === 'Premium')) {
            axios.post(`${process.env.REACT_APP_API_URL}/payments/get-active-stripe-subscription-by-customer-id`, {stripeCustomerId: studio.stripeCustomerId})
            .then((res) => {
                // there can be only one - but just in case, take the first
                setStripeSubscription(Array.isArray(res.data) ? res.data[0] : res.data)
            })
            .catch((error) => {
                console.log(error)
            })
        }

        // regardless of whether obtaining subscription was needed, loading is now complete
        setLoading(false)
    }

    const handleCheckoutSubmit = async () => {
        setProcessingCheckout(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/payments/create-studio-checkout-session`, {
                studioId: currentUser.uid,
                email: currentUser.email,
                name: currentUser.displayName,
                monthlyPrice: monthlyPrice,
            });

            if (response.data && response.data.url) {
                // Perform client-side redirection
                window.location.href = response.data.url;
            }

            setProcessingCheckout(false)
        } catch (error) {
            setProcessingCheckout(false)

            console.log("Problem during checkout:", error)
            toast.error("There was a problem during checkout");
        }
    };

    const handleAutoRenewChange = (autoRenew) => {
        axios.post(`${process.env.REACT_APP_API_URL}/studios/set-studio-subscription-auto-renew`, {
            studioId: studio._id,
            subscriptionId: studio.subscriptionId,
            autoRenew})
        .then(() => {
            getStudio()
        })
        .catch((error) => {
            console.log(error)
        })
    };

    const calculateRenewalData = () => {
        const renewalDate = moment.unix(stripeSubscription.current_period_end)
        return renewalDate.format('DD/MM/YYYY')
    }

    return (
        <div className='profile-container'>
            {
                (subsriptionsAllowed === false) ? (
                    <div style={customStyles.subscriptionContainer}>
                        <p style={{marginBottom: '20px'}}>You are on the {subscription} subscription tier. Changes are not currently permitted.</p>
                    </div>
                ) :
                (loading === true) ? (
                    <div style={customStyles.subscriptionContainer}>
                        <p style={{marginBottom: '20px'}}>Page loading, please wait...</p>
                    </div>
                ) :
                ((subscription === "Free") && (studio.subscriptionId || waitingforUpgrade)) ? (
                    <p style={{marginBottom: '20px'}}>Thank you. We are processing the new subscription. Please check back shortly.</p>
                ) : (subscription === "Premium") ? (
                    <div style={customStyles.subscriptionContainer}>
                        <p style={{marginBottom: '20px'}}>You are currently on the {subscription} subscription tier</p>

                        {/* display subscribed page */}

                        <p style={{ fontFamily: 'Proxima', marginTop: 10, textAlign: 'center' }}>
                            {studio.autoRenew ? 'Renewal Date:' : 'End Date:'}:  {calculateRenewalData()}
                        </p>

                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                            <p style={{ fontFamily: 'Proxima', marginRight: '8px' }}>Auto Renew</p>
                            <input
                                type="checkbox"
                                checked={studio.autoRenew}
                                onChange={() => handleAutoRenewChange(!studio.autoRenew)}
                            />
                        </div>
                    </div>
                ) : (
                    /* display subscription banners for subscribing */
                    <div style={customStyles.subscriptionContainer}>
                        <p style={{marginBottom: '20px'}}>You are currently on the {subscription} subscription tier</p>

                        <div style={customStyles.subscriptionBoxes}>
                            <div
                                style={{
                                    ...customStyles.subscriptionBox,
                                }}
                            >
                                <h2>Free</h2>
                                <p style={{ color: 'inherit' }}>Your studio is listed on the app, providing visibility to teachers and students</p>
                                <p style={{ color: 'inherit' }}>There is no charge for this tier</p>
                            </div>

                            <div
                                style={{
                                    ...customStyles.subscriptionBox,
                                    backgroundColor: '#a28f89',
                                    color: 'white',
                                }}
                            >
                                <h2>Premium</h2>
                                <p style={{ color: 'inherit' }}>Studio listing plus calendar, scheduling and teacher integration</p>
                                <p style={{ color: 'inherit' }}>£{monthlyPrice/100} per month</p>
                                <p style={{ color: 'inherit' }}>First 6 months at 50% discount, £{(monthlyPrice / 200).toFixed(2)} per month</p>
                            </div>
                        </div>

                        <button
                            id="checkout-and-portal-button"
                            style={customStyles.upgradeButtonStyle}
                            onClick={handleCheckoutSubmit}
                            disabled={processingCheckout}
                        >
                            {processingCheckout ? 'Proceeding to Checkout...' : 'Upgrade to Premium'}
                        </button>
                    </div>
                )
            }
        </div>
    );
};

export default Subscriptions;

const customStyles = {
    subscriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    // Styles for the subscription boxes
    subscriptionBoxes: {
        display: 'flex',
        marginTop: '20px',
    },
    subscriptionBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: '1px solid black',
        padding: '20px',
        textAlign: 'center',
        width: '400px',
        height: '150px',
    },
    upgradeButtonStyle: {
        marginTop: '40px',
        justifyContent: 'center',
        padding: '15px 30px',
        borderRadius: '15px',
        fontSize: '18px',
        backgroundColor: '#a28f89',
        border: '1px solid black',
        color: 'white',
        cursor: 'pointer',
        width: '300px',
        height: '50px',
    }
};