import React, { useState, useEffect} from 'react'
import moment from 'moment'
import { useAuth} from '../../../Context/AuthContext'
import axios from 'axios'
import { RRule } from 'rrule'
import { toast} from 'react-toastify'

export default function CreateContest({setOpenTimetable, getTimetables}) {

  const [saving, setSaving] = useState(false)

  let { currentUser} = useAuth()

  //form state pg 1
  const [title, setTitle] = useState("")
  const [dates, setDates] = useState([])
  const [dtstart, setDtstart] = useState("")
  const [until, setUntil] = useState("")

  useEffect(() => {
   if(dtstart){
     setUntil(moment(dtstart).add(6, 'days').format('YYYY-MM-DD'))
   }
  }, [dtstart])
  
  useEffect(()=>{
    if(dtstart && until){
      const rule = new RRule({
        freq: RRule.DAILY,
        dtstart: moment(`${dtstart}`).toDate(),
        until: moment(`${until}`).toDate(),
      })
      setDates(rule.all())
    } else {
      return
    }

  },[dtstart, until])

  useEffect(() => {
    if(dtstart){
      setTitle(`${moment(dtstart).format('Do MMMM')}`)
    }

  },[dtstart])
 
  const createTimetable = ()=>{
    let payload = {title, dates, start:dtstart, end: until, teachers:[], classes:[], published: false, studioId: currentUser.uid}

    if(!dtstart && !until){
      toast.error("Please add date")
    } else {
      setSaving(true)
      axios.post(`${process.env.REACT_APP_API_URL}/studios/new-timetable`, payload)
      .then(function(res){
        setOpenTimetable(false)
        setTitle("")
        setDtstart("")
        setUntil("")
        getTimetables()
        setSaving(false)
      })
      .catch((e)=>{
        toast.error("Error")
        setSaving(false)
        console.log(e)
      })
    }
   }

  return (
    <div id='side-modal'>
        <div id='side-modal-container'>
            <button onClick={() => setOpenTimetable(false)} id='close-contest-btn'>x</button>

            <div className='side-modal-header'>
                <p className='om-header' style={{fontSize: 28}}>Create weekly timetable</p>

                <p className='om-subheader' style={{fontSize: 16, margin: '10px 0'}}>Timetables are created on a weekly basis, please fill in the necessary information</p>
            </div>
              <div className='new-contest-container'>

                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Title</p>
                <input className='modal-input' disabled={!dtstart} type='text' placeholder='eg. w/c 05th May' value={title} onChange={(e) => setTitle(e.target.value)}/>

                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Start date</p>
                <input className='modal-input' type='date' value={dtstart} onChange={(e) => setDtstart(e.target.value)}/>

                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>End date</p>
                <input className='modal-input' type='date' value={until} disabled/>

          
                <button style={{marginTop: 20}} className='primary-btn' disabled={saving} onClick={createTimetable}>{saving ? "Saving..." : "Create"}</button>
              </div>

        </div>
    </div>
  )
}
