import React, { useState, useEffect } from 'react'
import { yogaStyles} from '../../../Utilities/yogaStyles'
import { useAuth } from '../../../Context/AuthContext'
import { toast } from 'react-toastify'
import axios from 'axios'
import moment from 'moment'

export default function EditClassModal({teachers, classInfo, dates, classes, setOpenEditClass, getTimetable, timetableId}) {

    let { currentUser } = useAuth()

    const [name, setName] = useState("")
    const [style, setStyle] = useState("")
    const [teacherId, setTeacherId] = useState("")
    const [teacherName, setTeacherName] = useState("")
    const [teacherImg, setTeacherImg] = useState("")
    const [start, setStart] = useState("")
    const [end, setEnd] = useState("")
    const [day, setDay] = useState("")
    const [spaces, setSpaces] = useState("")
    const [price, setPrice] = useState("")

    const [saving, setSaving] = useState(false)

    useEffect(() => {

        if(classInfo){
            //Set form state
            let { day, name, style, teacherId, start, end, spaces, price } = classInfo

            setDay(day)
            setName(name);
            setStyle(style);
            setTeacherId(teacherId);
            setStart(start);
            setEnd(end);
            setSpaces(spaces);
            setPrice(price)
        } else {
            return
        }
    }, [classInfo])

   
    useEffect(() => {
        if(teacherId){
            let selected = teachers.filter((teacher) => teacher.id === teacherId)
            setTeacherName(selected[0]?.name)
            setTeacherImg(selected[0]?.img)
        } else {
            //If unassigned, clear all related fields
            setTeacherName("")
            setTeacherImg("")
        }

    }, [teacherId])

    const handleSubmit = () => {

        let payload = {
            _id: classInfo?._id,
            name,
            style,
            teacherName,
            teacherId,
            teacherImg,
            day,
            studioId: currentUser.uid,
            studioName: currentUser.displayName,
            start,
            end,
            spaces,
            price,
        }
        if(!name || !style || !day || !start || !end || !spaces){
            toast.error("Please add all fields")        
        } else {
            setSaving(true)

            let filtered = classes.filter((item) => item._id !== classInfo._id)     //Remove existing object from array. Could be done in a better way?
            axios.post(`${process.env.REACT_APP_API_URL}/studios/update-timetable-classes`, {timetableId, classes: [...filtered, payload]})
            .then(() => {
                setOpenEditClass(false)
                getTimetable()
                toast.success("Successfully updated")
                setSaving(false)

            })
            .catch((e) => {
                console.log(e)
                toast.error("Error creating")
                setSaving(false)

            })
        }
    }

  return (
    <div id='side-modal'>
        <div id='side-modal-container'>
            <button onClick={() => setOpenEditClass(false)} id='close-contest-btn'>x</button>

            <div className='side-modal-header'>
                <p className='om-header' style={{fontSize: 28}}>Edit Class</p>

                <p className='om-subheader' style={{fontSize: 16, margin: '10px 0'}}>Add a one-off class to this timetable</p>
            </div>
              <div className='new-contest-container'>
                <p className='om-header' style={{fontSize: 20, marginBottom: 10}}>Date</p>
                <select className='modal-input' value={day} onChange={(e) => setDay(e.target.value)}>
                    <option disabled value="">Please select</option>
                    {dates.map((date, i) => {
                        return (
                            <option key={i} value={moment(date).format('YYYY-MM-DD')}>{moment(date).format("ddd, Do MMM")}</option>
                        )
                    })}
                </select>


                <p className='om-header' style={{fontSize: 20, marginBottom: 10}}>Class Name</p>
                <input className='modal-input' type='text' placeholder='eg. Hatha'  value={name} onChange={(e) => setName(e.target.value)}/>
                
                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Yoga Style (used for filtering only)</p>
                <select className='modal-input'  value={style} onChange={(e) => setStyle(e.target.value)}>
                    <option value="">Please select</option>
                    {yogaStyles.map((style, i) => {
                        return <option key={i} value={style}>{style}</option>
                    })}

                </select>

                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Assign Teacher</p>
                <select className='modal-input'  value={teacherId} onChange={(e) => setTeacherId(e.target.value)}>
                    <option value="">Unallocated</option>
                    {teachers.map((teacher, i) => {
                        return (
                            <option key={i} value={teacher.id}>{teacher.name}</option>
                        )
                    })}
                </select>

               

                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{width: '45%'}}>
                        <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Start time</p>
                        <input className='modal-input'  type='time' value={start} onChange={(e) => setStart(e.target.value)}/>
                    </div>
                    <div style={{width: '45%'}}>
                        <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>End time</p>
                        <input className='modal-input'  type='time' value={end} onChange={(e) => setEnd(e.target.value)}/>
                    </div>

                   
                </div>

                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{width: '45%'}}>
                        <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Class price</p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '5px' }}>£</span>
                            <input className='modal-input' type='number' value={price} onChange={(e) => setPrice(e.target.value)} />
                        </div>
                    </div>
                    <div style={{width: '45%'}}>
                        <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Spaces available</p>
                        <input className='modal-input'  type='number' value={spaces} onChange={(e) => setSpaces(e.target.value)}/>
                    </div>
                </div>
                
                <button style={{marginTop: 20}} className='primary-btn' onClick={handleSubmit} disabled={saving}>{saving? "Updating" :"Update class"}</button>
              </div>

        </div>
    </div>
  )
}
