import React, { useState, useEffect } from 'react'
import './Messages.css'
import search from '../../Assets/Icons/search.png'
import moment from 'moment'
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom'
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import LockedOverlay from './LockedOverlay'

export default function Messages() {

 let navigate = useNavigate()
 const { navOpen } = useOutletContext();
 let { currentUser, subscription } = useAuth()

  const [teacherChats, setTeacherChats] = useState([])
  const [chats, setChats] = useState([])
  const [selectedChat, setSelectedChat] = useState(null)

  useEffect(() => {
    getChats()
  }, [])

  const getChats = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/chats/get-studio-chats-by-id`, {id: currentUser.uid})
      .then((res) => {
        let teacherSent = res.data.filter((chat) => chat.senderType === "Teacher")
        setTeacherChats(teacherSent)
        let studentSent = res.data.filter((chat) => chat.senderType === "Student")
        setChats(studentSent)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const [tab, setTab] = useState(1)

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>
      {subscription === 'Free' && <LockedOverlay />}

      <div id='message-contents'>

        <div className='om-row' style={{justifyContent: 'space-between'}}>
          <p className='om-header'>Inbox</p>
          <div className='om-row'>
            <div className='om-input' style={{paddingLeft: 'unset', width: 250, marginRight: 10, position: 'relative'}}>
              <img src={search} alt='Search icon' style={{height: 25, position: 'absolute', left: 10, top: 10}}/>
              <input style={{border: 'unset', background: 'unset', height: '100%', width: '100%', paddingLeft: 50, fontSize: 16}}  placeholder='Search messages' />
            </div>
            <button className='primary-btn'>Search</button>
          </div>
        </div>

        <div className='om-row'>
            <div id='messages-left-container'>
              <div className='tab-container' style={{justifyContent: 'left', margin: 0, borderRadius: 0}}>
                <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
                  <p style={{color:'#313131'}}>Teachers</p>
                  <div className='tab-square'>
                    <p style={{color:'#313131'}}>{teacherChats.length}</p>
                  </div>
                </div>
                <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
                  <p style={{color:'#313131'}}>Students</p>
                  <div className='tab-square'>
                    <p style={{color:'#313131'}}>{chats.length}</p>
                  </div>
                </div>

              </div>

              {tab === 1 && (
              teacherChats.length < 1 ? 
              <p style={{textAlign:'center', color: 'grey', marginTop: 100}}>Your inbox is empty</p> 
              : 
              teacherChats.map((msg, i) => {
                return (
                  <div key={i} className='msg-snapshot' onClick={() =>{ setSelectedChat(msg); navigate(msg._id) } }>
                    {msg.senderImg ?
                    <div className='msg-avatar' style={{backgroundImage: `url(${msg.senderImg})`, backgroundSize:'cover', backgroundPosition:'center'}}>
                    </div>
                    :
                    <div className='msg-avatar'>
                        <p>{msg.senderName.split(" ")[0].charAt(0)}{msg.senderName.split(" ")[1].charAt(0)}</p>
                    </div>
                    }
                    

                    <div style={{flexDirection: 'column', width: '80%', height: '100%', overflow: 'hidden'}}>
                    <div style={msg.studioRead ? {display: 'flex', justifyContent: 'space-between', marginBottom: 5, color: 'grey', fontSize: 14} : {display: 'flex', justifyContent: 'space-between', marginBottom: 5, color: '#313131', fontSize: 14} }>
                        <p style={{textTransform: 'uppercase'}}>{msg.senderName}</p>
                        <p>{moment(msg.lastMsgAdded).fromNow()}</p>
                      </div>

                      <p style={msg.studioRead ? {color:'grey'} :{color:'#313131'}}>{msg.lastMsg}</p>

                    </div>

                  </div>
                )
              })
              )
            }

            {tab === 2 && 
              (chats.length < 1 ? 
              <p style={{textAlign:'center', color: 'grey', marginTop: 100}}>Your inbox is empty</p> 
              : 
              chats.map((msg, i) => {
                return (
                  <div key={i} className='msg-snapshot' onClick={() =>{ setSelectedChat(msg); navigate(msg._id) } }>
                    <div className='msg-avatar'>
                        <p>{msg.senderName.split(" ")[0].charAt(0)}{msg.senderName.split(" ")[1].charAt(0)}</p>
                    </div>

                    <div style={{flexDirection: 'column', width: '80%', height: '100%', overflow: 'hidden'}}>
                      <div style={msg.studioRead ? {display: 'flex', justifyContent: 'space-between', marginBottom: 5, color: 'grey', fontSize: 14} : {display: 'flex', justifyContent: 'space-between', marginBottom: 5, color: '#313131', fontSize: 14} }>
                        <p style={{textTransform: 'uppercase'}}>{msg.senderName}</p>
                        <p>{moment(msg.lastMsgAdded).fromNow()}</p>
                      </div>

                      <p>{msg.lastMsg}</p>

                    </div>

                  </div>
                )
              })
              )
            }
            

            </div>
            <div id='messages-right-container'>
              <Outlet context={{getChats, senderName: currentUser.displayName, selectedChat}}/>
            </div>
           
        </div>

       

      </div>
    </div>
  )
}
