import React, { useState} from 'react'
import './Auth.css'
import tick from '../../Assets/Icons/check.png'
import { Link } from 'react-router-dom'

export default function CreateAccount({setTab, accountType, setAccountType, setStep, step}) {

  return (

    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>

            {step === 1 &&
            <>

            <div className={accountType === "Business" ? 'profile-choice-active' : 'profile-choice'} onClick={() => setAccountType("Studio")}>
                <div className='studio-avatar'>

                </div>

                <div>
                    <p className='om-header' style={{fontSize: 26, margin: 0}}>Sign up as a Studio - Free Account</p>
                    <p className='om-subheader' style={{fontSize: 16, margin: 0, marginTop: 10}}>List your studio on the Om Yoga mobile app for free allowing students all over to find you and your classes.</p>

                </div>

                <div className='check-circle'>
                    {accountType === "Studio" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                </div>
            </div>  

            <a href='https://om-yoga.co.uk/create-account'>
            <div className={accountType === "Freelancer" ? 'profile-choice-active' : 'profile-choice'}  >
                <div className='teacher-avatar'>

                </div>
                <div>
                    <p className='om-header' style={{fontSize: 26, margin: 0}}>Sign up as a Student or Teacher</p>
                    <p className='om-subheader' style={{fontSize: 16, margin: 0, marginTop: 10}}>Find classes, teachers and studios along with providing teachers with tools to manage all their classes.</p>

                </div>

                <div className='check-circle'>
                    {accountType === "Freelancer" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                </div>
            </div>   
            </a>

            <p style={{fontSize: 16}} className='om-subheader'>Already have an account? <span onClick={() => setTab("Log In")}>Log In</span></p>           
        
            {accountType && 
            <Link to='/create-account'>
                <button style={{width: 100, marginTop: 30}} className='primary-btn'>
                    Continue
                </button>
            </Link>}
            </>
            }

            
 
    </div>
  )
}
