import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Past({pastTimetables}) {
  
  let navigate = useNavigate()

  return (
    <div>
    {pastTimetables.length < 1 ?
    <p>No past timetables</p>
    : 
    <table className='timetable-list'>
      <thead>
        <tr>
          <th>Title</th>
          <th>Classes</th>
          <th>Teachers</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
      {pastTimetables.map((timetable, i) => {
      return (
          <tr key={i} onClick={() => navigate(`${timetable._id}`)}>
              <td>{timetable.title}</td>
              <td>{timetable.classes.length}</td>
              <td>{timetable.teachers.length}</td>
              <td style={!timetable.published ? {color: 'crimson'} : {}}>{timetable.published ? "Published" : "Unpublished"}</td>
          </tr>
       
          )
        })
        }

      </tbody>
    </table>
  
    }
  </div>
  )
}
