import React, { useState, useEffect} from 'react'
import './Timetable.css'
import axios from 'axios'
import Current from './Current'
import Past from './Past'
import TimetableCreate from './TimetableCreate'
import { useAuth } from '../../../Context/AuthContext'
import moment from 'moment'
import LockedOverlay from '../LockedOverlay'
import { useOutletContext } from 'react-router-dom'

export default function Timetable() {
  
  const { navOpen } = useOutletContext();
  let { currentUser, subscription } = useAuth()
  const [tab, setTab] = useState(1)
  const [openTimetable, setOpenTimetable] = useState(false)
  const [currentTimetables, setCurrentTimetables] = useState([])
  const [pastTimetables, setPastTimetables] = useState([])

  const getTimetables = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/studios/get-timetables-by-studio`, {studioId: currentUser.uid})
    .then((res) => {
      let future = res.data.filter((item) => moment(item.end).isAfter())
      let past = res.data.filter((item) => moment(item.end).isBefore())
      setCurrentTimetables(future)
      setPastTimetables(past)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {
    getTimetables()
  }, [])

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>
      {subscription === 'Free' && <LockedOverlay />}

      <div id='timetable-dash-contents'>
        {openTimetable && <TimetableCreate  setOpenTimetable={setOpenTimetable} getTimetables={getTimetables}/>}

          <button className='primary-btn' style={{position: 'absolute', right: '1rem', top: '1rem'}} onClick={() => setOpenTimetable(true)}>Create new timetable +</button>

        <p className='om-header'>Timetable</p>
        <p className='om-subheader'>Create templates, view and manage timetables for classes and teachers</p>

        <div className='tab-container' style={{width: 400}}>
          <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
            Current timetables
          </div>
          <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
            Past timetables
          </div>
         
        </div>

        <div id='timetable-container'>

          {tab === 1  && <Current currentTimetables={currentTimetables}/>}
          {tab === 2  && <Past pastTimetables={pastTimetables}/>}

        </div>

      </div>
    </div>
  )
}
